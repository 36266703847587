import React from "react";
import html2canvas from "html2canvas";

const ActiveButtons = ({ name, email, isFormComplete }) => {
  const isEmailValid = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@vintglobal\.com\.br$/;
    return emailRegex.test(email);
  };

  const handleAction = async (elementId, actionType, fileName = "") => {
    if (!isEmailValid()) {
      alert(
        "E-mail inválido! Certifique-se de que o e-mail termine com '@vintglobal.com.br'."
      );
      return;
    }

    try {
      const element = document.getElementById(elementId);
      const spans = element.getElementsByTagName("span");

      // Salvar as posições originais dos spans
      const originalSpanPositions = [];
      for (let i = 0; i < spans.length; i++) {
        originalSpanPositions.push(spans[i].getBoundingClientRect());
      }

      // Mover todos os spans para cima (ajustar sua posição)
      for (let i = 0; i < spans.length; i++) {
        spans[i].style.position = "relative";
        spans[i].style.top = "-6px"; // Ajuste de 5px para mover para cima
      }

      // Captura o elemento usando html2canvas
      const canvas = await html2canvas(element, {
        scale: 2,
        backgroundColor: null, // Evitar fundo branco
        logging: false, // Desativa logs de erro
      });

      // Restaurar as posições originais dos spans
      for (let i = 0; i < spans.length; i++) {
        spans[i].style.position = "";
        spans[i].style.top = ""; // Remove a modificação no "top"
      }

      // Executa a ação de download ou cópia
      if (actionType === "download") {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png", 1.0);
        link.download = fileName;
        link.click();
      } else if (actionType === "copy") {
        canvas.toBlob(async (blob) => {
          if (navigator.clipboard?.write) {
            await navigator.clipboard.write([
              new ClipboardItem({ "image/png": blob }),
            ]);
            alert("Assinatura copiada para a área de transferência.");
          } else {
            await navigator.clipboard.writeText(canvas.toDataURL("image/png"));
            alert("URL da assinatura copiada para a área de transferência.");
          }
        });
      }
    } catch (err) {
      console.error("Erro ao realizar ação:", err);
    }
  };

  return (
    <div className="flex items-center justify-center lg:space-x-2 2xl:space-x-4 lg:mt-5 2xl:mt-12 xl:space-x-2 xl:mt-10">
      {/* Botões com Foto */}
      <div
        id="buttonsWithPhoto"
        className="text-white flex items-center justify-center lg:space-x-1 2xl:space-x-2 2xl:text-[17px] xl:space-x-1 xl:text-[13px] lg:text-[11px]"
      >
        <button
          className="flex rounded-s-3xl items-center justify-center lg:w-[7.5rem] lg:h-9 2xl:w-[11rem] xl:w-36 xl:h-10 2xl:h-12 bg-primary-lilac3x"
          onClick={() =>
            handleAction(
              "designWithAPhoto", // Elemento com foto
              "download",
              `${name}-Com-Foto-Vint.png`
            )
          }
          id="buttonDownloadWithPhoto"
          disabled={!isFormComplete}
        >
          <span className="material-symbols-outlined">download</span>
          <span className="font-semibold px-1">Baixar com foto</span>
        </button>
      </div>

      {/* Botões sem Foto */}
      <div
        id="buttonsWithoutPhoto"
        className="text-white flex items-center justify-center lg:space-x-1 2xl:space-x-2 2xl:text-[17px] xl:space-x-1 xl:text-[13px] lg:text-[11px]"
      >
        <button
          className="flex rounded-e-3xl items-center justify-center lg:w-[7.5rem] lg:h-9 2xl:w-[11rem] xl:w-36 xl:h-10 2xl:h-12 bg-primary-lilac3x"
          onClick={() =>
            handleAction(
              "designWithoutAPhoto", // Elemento sem foto
              "download",
              `${name}-Sem-Foto-Vint.png`
            )
          }
          id="buttonDownloadWithoutPhoto"
          disabled={!isFormComplete}
        >
          <span className="material-symbols-outlined">download</span>
          <span className="font-semibold px-1">Baixar sem foto</span>
        </button>
      </div>
    </div>
  );
};

export default ActiveButtons;
